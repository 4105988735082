<template>
  <b-card title="Informació personal">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col cols="12" class="mb-1">
          <h5>Nom</h5>
          <b-card-text>{{ name }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h5>{{ idType }}</h5>
          <b-card-text>{{ idNumber }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h5>Adreça</h5>
          <b-card-text>{{ address }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h5>Telèfon</h5>
          <b-card-text>{{ phone }}</b-card-text>
        </b-col>
        <b-col cols="12" class="mb-1">
          <h5>Email</h5>
          <b-card-text>{{ email }}</b-card-text>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="danger"
            @click="onResetClicked"
            :disabled="!canResetUser"
          >
            {{ canResetUser ? "Reset d'usuari" : "Sense usuari creat" }}
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BOverlay, BButton } from "bootstrap-vue";
import {
  getFullAddress,
  getIdTypeName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BOverlay,
    BButton,
  },
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.getters["client/loading"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    name() {
      return this.client?.fullName || this.$t("No definit");
    },
    phone() {
      return this.client?.phone || this.$t("No definit");
    },
    email() {
      return this.client?.email || this.$t("No definit");
    },
    idType() {
      return getIdTypeName(this.client?.idType) || this.$t("Document");
    },
    idNumber() {
      return this.client?.idNumber || this.$t("No definit");
    },
    address() {
      return (
        getFullAddress(this.client?.location, this.$i18n.locale) ||
        this.$t("No definit")
      );
    },
    clientUuid() {
      return this.client?.uuid || null;
    },
    userUuid() {
      return this.client?.user?.uuid || null;
    },
    canResetUser() {
      return this.clientUuid && this.userUuid;
    },
  },
  created() {
    if (!this.loading) this.fetchClient();
  },
  beforeDestroy() {
    this.$store.dispatch("client/reset");
    this.setPageTitle(null);
  },
  methods: {
    fetchClient() {
      const { clientUuid } = this.$route.params;

      if (!clientUuid) {
        notifyError("Error", "The client uuid is not defined");
        return;
      }

      this.$store
        .dispatch("client/fetchClient", clientUuid)
        .then(() => {
          this.setPageTitle(this.client);
        })
        .catch(() => {
          notifyError(
            this.$t("errors.fetchClient.title"),
            this.$t("errors.fetchClient.description")
          );
        });
    },
    setPageTitle(client) {
      this.$store.dispatch("app/setPageTitle", client?.fullName || null);
      this.$store.dispatch("app/setPageSubtitle", client?.idNumber || null);
    },
    onResetClicked() {
      this.$store.dispatch("app/setLoading", true);

      const userUuid = this.userUuid;

      this.$store
        .dispatch("client/updateClient", {
          uuid: this.clientUuid,
          user: null,
        })
        .then(() => {
          this.$store
            .dispatch("user/deleteUser", userUuid)
            .then(() => {
              notifySuccess(
                "Molt bé! 🙌",
                "S'ha resetejat l'usuari del client. Ja es pot tornar a registrar"
              );
            })
            .catch(() => {
              notifyError(
                this.$t("errors.resetClientUser.title"),
                this.$t("errors.resetClientUser.description")
              );
            })
            .finally(() => this.$store.dispatch("app/setLoading", false));
        })
        .catch(() => {
          notifyError(
            this.$t("errors.resetClientUser.title"),
            this.$t("errors.resetClientUser.description")
          );
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
